import { getFirestore, collection, query, where, getDocs, writeBatch, doc } from 'firebase/firestore'

export default {
  state: {
    checks: null,
    archivedLoaded: false
  },
  mutations: {
    setCheck(state, check) {
      if (check && check.checkId) {
        if (!state.checks) { state.checks = {} }
        state.checks[check.checkId] = check
      }
    },
    setArchivedLoaded(state) {
      state.archivedLoaded = true
    },
    updateCheck(state, { checkId, data }) {
      if (checkId && state.checks[checkId]) {
        for (const field of Object.keys(data)) {
          state.checks[checkId][field] = data[field]
        }
      }
    },
    deleteCheckFromStore(state, checkId) {
      if (checkId && state.checks[checkId]) {
        delete state.checks[checkId]
      }
    },
    clearInfo(state) {
      state.checks = null
      state.archivedLoaded = false
    }
  },
  actions: {
    async fetchChecks({ commit }, active) {
      try {
        const checksRef = collection(getFirestore(), 'apps/law-clients/checks')
        const q = query(checksRef, where('active', '==', active))
        const checks = await getDocs(q)
        if (!checks.empty) {
          for (const check of checks.docs) {
            const checkData = check.data()
            checkData.checkId = check.id
            if (checkData.timestamp && checkData.timestamp.toDate) { checkData.timestamp = checkData.timestamp.toDate() }
            await commit('setCheck', checkData)
          }
        }

        if (!active) {
          await commit('setArchivedLoaded')
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async addCheck({ commit }, data) {
      if (!data) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const checkId = doc(collection(getFirestore(), `apps/law-clients/checks`)).id

        data.active = true
        data.timestamp = new Date()

        const checkRef = doc(getFirestore(), `apps/law-clients/checks/${checkId}`)
        batch.set(checkRef, data)

        await batch.commit()

        data.checkId = checkId

        await commit('setCheck', data)

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async setArchivedCheck({ commit }, { active, checkId }) {
      if (!checkId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const checkRef = doc(getFirestore(), `apps/law-clients/checks/${checkId}`)
        batch.update(checkRef, {
          active: active
        })

        await batch.commit()

        await commit('updateCheck', { checkId, data: { active } })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteCheck({ commit }, checkId) {
      if (!checkId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const checkRef = doc(getFirestore(), `apps/law-clients/checks/${checkId}`)
        batch.delete(checkRef)

        await batch.commit()

        await commit('deleteCheckFromStore', checkId)

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
  },
  getters: {
    checks: s => s.checks,
    archivedLoaded: s => s.archivedLoaded
  }
}