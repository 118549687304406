<template>
  <div id="app" class="min-h-screen min-w-full h-full w-auto inset-0 bg-gradient-to-r from-gray-200 to-indigo-200 px-4 pt-16 pb-4">
    <div>
      <Navbar
        v-if="user && !$route.meta.hideNavbar"
        :email="user.email"
      />

      <router-view/>
    </div>

    <AppNotifications />
  </div>
</template>

<script>
import Navbar from '@/components/app/navigation/Navbar'
import AppNotifications from '@/components/app/AppNotifications'
import useNotifications from '@/composables/useNotifications'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

  export default {
    name: 'App',
    setup () {
      const { toastify } = useNotifications()
      return { toastify }
    },
    components: {
      Navbar, AppNotifications
    },
    data: () => ({
      user: null
    }),
    computed: {
      error () {
        return this.$store.getters.error
      }
    },
    watch: {
      error (errorText) {
        this.toastify.error(errorText)
      }
    },
    async created() {
      const auth = getAuth()
      onAuthStateChanged(auth, user => {
        if (user) {
          this.user = user
        } else {
          this.user = null
        }
      })
    }
  }
</script>