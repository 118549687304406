import { getAuth } from 'firebase/auth'
import { getFirestore, doc, collection, writeBatch, query, getDocs, orderBy, limit, startAfter, where } from 'firebase/firestore'

export default {
  state: {
    projects: {},
    allProjectsFetched: {},
    lastFetchedProject: {}
  },
  mutations: {
    setAllProjectsFetched(state, clientId) {
      if (clientId) {
        state.allProjectsFetched[clientId] = true
      }
    },
    setLastFetchedProject(state, { clientId, lastFetchedProject }) {
      if (clientId && lastFetchedProject) {
        state.lastFetchedProject[clientId] = lastFetchedProject
      }
    },
    setProjectInfo(state, projectInfo) {
      if (projectInfo && projectInfo.projectId && projectInfo.clientId) {
        if (!state.projects[projectInfo.clientId]) {
          state.projects[projectInfo.clientId] = {}
        }

        if (!state.projects[projectInfo.clientId][projectInfo.projectId]) {
          state.projects[projectInfo.clientId][projectInfo.projectId] = {}
        }

        for (const i in Object.keys(projectInfo)) {
          state.projects[projectInfo.clientId][projectInfo.projectId][Object.keys(projectInfo)[i]] = projectInfo[Object.keys(projectInfo)[i]]
        }
      }
    },
    deleteProjectFromStore(state, { clientId, projectId }) {
      if (clientId && projectId && state.projects && state.projects[clientId] && state.projects[clientId][projectId]) {
        let clientsProjects = JSON.parse(JSON.stringify(state.projects[clientId]))
        delete clientsProjects[projectId]
        state.projects[clientId] = JSON.parse(JSON.stringify(clientsProjects))
      }
    },
    clearInfo(state) {
      state.projects = {}
      state.allProjectsFetched = {}
      state.lastFetchedProject = {}
    }
  },
  actions: {
    async fetchProjectsList({ commit }, { clientId, noLimit }) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      const maxLimit = 10

      try {
        const wheres = [orderBy('started', 'desc'), where('clientId', '==', clientId)]
        if (!noLimit) { wheres.push(limit(maxLimit)) }
        const projectsRef = collection(getFirestore(), 'apps/law-clients/projects')

        const lastFetchedProject = await this.getters.lastFetchedProject
        let lastDoc
        if (lastFetchedProject[clientId]) {
          lastDoc = lastFetchedProject[clientId]
        }
        if (lastDoc) {
          wheres.push(startAfter(lastDoc))
        }

        const q = query(projectsRef, ...wheres)

        await getDocs(q)
          .then(async querySnapshot => {
            querySnapshot.forEach(async project => {
              let projectData = {}
              projectData = project.data()
              projectData.projectId = project.id
              projectData.clientId = clientId
              if (projectData.started && projectData.started.toDate) { projectData.started = projectData.started.toDate() }
              if (projectData.timestamp && projectData.timestamp.toDate) { projectData.timestamp = projectData.timestamp.toDate() }
              await commit('setProjectInfo', projectData)
            })

            if (querySnapshot.size < maxLimit) {
              await commit('setAllProjectsFetched', clientId)
            }

            await commit('setLastFetchedProject', { clientId: clientId, lastFetchedProject: querySnapshot.docs[querySnapshot.docs.length - 1] })
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async createProject({ commit }, { clientId, project }) {
      if (!clientId || !project) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = getAuth()?.currentUser?.uid

      let projectId = null

      let data = {}

      data.userId = uid
      data.timestamp = new Date()
      data.clientId = clientId

      if (project.started) {
        data.started = new Date(project.started)
      } else {
        data.started = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      }

      if (!project.clientEntity) {
        data.clientEntity = ''
      } else {
        data.clientEntity = project.clientEntity
      }

      if (!project.comment) {
        data.comment = ''
      } else {
        data.comment = project.comment
      }

      if (!project.contrAgent) {
        data.contrAgent = ''
      } else {
        data.contrAgent = project.contrAgent
      }

      if (!project.contractType) {
        data.contractType = ''
      } else {
        data.contractType = project.contractType
      }

      data.links = []

      if (project.links) {
        for (const linkIdx in project.links) {
          if (project.links[linkIdx] && project.links[linkIdx].url && project.links[linkIdx].url.length <= 100) {
            if (!project.links[linkIdx].urlText) {
              project.links[linkIdx].urlText = project.links[linkIdx].url
            }
            if (project.links[linkIdx].urlText.length > 100) {
              project.links[linkIdx].urlText.slice(0, 99)
            }

            data.links.push(project.links[linkIdx])
          }
        }
      }

      try {
        const batch = writeBatch(getFirestore())

        const newId = doc(collection(getFirestore(), 'apps/law-clients/projects')).id

        const projectRef = doc(getFirestore(), `apps/law-clients/projects/${newId}`)
        batch.set(projectRef, data)

        await batch.commit().then(async () => {
          projectId = newId
          data.projectId = newId
          data.clientId = clientId

          await commit('setProjectInfo', data)

          return projectId
        })
      } catch (e) {
        commit('setError', e)
        return projectId
      }

      return projectId
    },
    async editProject({ commit }, { clientId, projectId, project }) {
      if (!clientId || !projectId || !project) {
        commit('setError', 'Ошибка')
        return false
      }

      let data = project
      delete data.clientId
      delete data.projectId

      if (project.started) {
        data.started = new Date(project.started)
      } else {
        data.started = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      }

      let links = []

      if (project.links) {
        for (const linkIdx in project.links) {
          if (project.links[linkIdx] && project.links[linkIdx].url && project.links[linkIdx].url.length <= 100) {
            if (!project.links[linkIdx].urlText) {
              project.links[linkIdx].urlText = project.links[linkIdx].url
            }
            if (project.links[linkIdx].urlText.length > 100) {
              project.links[linkIdx].urlText.slice(0, 99)
            }

            links.push(project.links[linkIdx])
          }
        }
      }

      data.links = links

      try {
        const batch = writeBatch(getFirestore())
        const projectRef = doc(getFirestore(), `apps/law-clients/projects/${projectId}`)
        batch.update(projectRef, data)

        await batch.commit().then(async () => {
          data.projectId = projectId
          data.clientId = clientId

          await commit('setProjectInfo', data)

          return true
        })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async removeProject({ commit }, { clientId, projectId }) {
      if (!clientId || !projectId) {
        commit('setError', 'Ошибка')
        return false
      }

      const batchArray = []
      batchArray.push(writeBatch(getFirestore()))
      let count = 0
      let batchIndex = 0
      const countLimit = 495

      try {
        const clientProjectRef = doc(getFirestore(), `apps/law-clients/projects/${projectId}`)
        batchArray[batchIndex].delete(clientProjectRef)
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }

        await batchArray.forEach(async batch => await batch.commit())

        await commit('deleteProjectFromStore', { clientId, projectId })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    projects: s => s.projects,
    allProjectsFetched: s => s.allProjectsFetched,
    lastFetchedProject: s => s.lastFetchedProject
  }
}