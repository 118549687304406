import { createStore } from 'vuex'

import auth from './auth'
import clients from './clients'
import projects from './projects'
import checks from './checks'

export default createStore({
  state: {
    error: null
  },
  mutations: {
    setError(state, error) {
      console.log(error)
      if (error) {
        state.error = error + ''
      } else {
        state.error = null
      }
    }
  },
  getters: {
    error: s => s.error,
  },
  modules: {
    auth, clients, projects, checks
  }
})
