import { getFirestore, doc, collection, getDoc, writeBatch, query, where, getDocs, orderBy, arrayUnion } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import useNotifications from '@/composables/useNotifications'

export default {
  state: {
    clients: {},
    clientsFetched: false,
    artchievedClientsFetched: false
  },
  mutations: {
    setClientsFetched(state) {
      state.clientsFetched = true
    },
    setArchievedClientsFetched(state) {
      state.artchievedClientsFetched = true
    },
    setClientInfo(state, clientInfo) {
      if (clientInfo && clientInfo.id) {
        if (!state.clients[clientInfo.id]) {
          state.clients[clientInfo.id] = {}
        }

        for (const i in Object.keys(clientInfo)) {
          state.clients[clientInfo.id][Object.keys(clientInfo)[i]] = clientInfo[Object.keys(clientInfo)[i]]
        }
      }
    },
    setClientActive(state, { id, active }) {
      if (id) {
        state.clients[id].active = active
      }
    },
    setClientName(state, { id, name }) {
      if (id && name) {
        state.clients[id].name = name
      }
    },
    setClientEntities(state, { id, entities }) {
      if (id && entities) {
        state.clients[id].entities = entities
      }
    },
    setClientLinks(state, { id, links }) {
      if (id && links) {
        state.clients[id].links = links
      }
    },
    addClientComment(state, { id, comment }) {
      if (id && comment) {
        if (!state.clients[id]) {
          state.clients[id] = {}
        }

        if (!state.clients[id].comments) {
          state.clients[id].comments = []
        }

        state.clients[id].comments.unshift(comment)
      }
    },
    setDeletedClient(state, clientId) {
      if (state.clients[clientId]) {
        const newClients = state.clients
        delete newClients[clientId]
        state.clients = newClients
      }
    },
    addBillToStore(state, { clientId, bill }) {
      if (!clientId || !bill) { return }
      if (!state.clients[clientId]) { return }
      if (!state.clients[clientId].bills) { state.clients[clientId].bills = [] }
      const index = state.clients[clientId].bills.findIndex(bll => bll.billId === bill.billId)
      if (index >= 0) { state.clients[clientId].bills.splice(index, 1) }
      state.clients[clientId].bills.push(bill)
    },
    updateBillInStore(state, { clientId, billId, data }) {
      if (!clientId || !billId || !data) { return }
      if (!state.clients[clientId] || !state.clients[clientId].bills) { return }
      const index = state.clients[clientId].bills.findIndex(bill => bill.billId === billId)
      for (const field of Object.keys(data)) {
        state.clients[clientId].bills[index][field] = data[field]
      }
    },
    deleteBillFromStore(state, { billId, clientId }) {
      if (!clientId || !billId || !state.clients[clientId] || !state.clients[clientId].bills || !state.clients[clientId].bills.length) { return }

      const index = state.clients[clientId].bills.findIndex(bill => bill.billId === billId)
      state.clients[clientId].bills.splice(index, 1)
    },
    addBillItemToStore(state, { clientId, billId, data }) {
      if (!clientId || !billId || !data) { return }
      if (!state.clients[clientId] || !state.clients[clientId].bills) { return }
      const index = state.clients[clientId].bills.findIndex(bill => bill.billId === billId)
      if (index < 0) { return }
      state.clients[clientId].bills[index].items.push(data)
    },
    removeBillItemToStore(state, { clientId, billId, id }) {
      if (!clientId || !billId || !id) { return }
      if (!state.clients[clientId] || !state.clients[clientId].bills) { return }
      const index = state.clients[clientId].bills.findIndex(bill => bill.billId === billId)
      if (index < 0) { return }
      const itemIndex = state.clients[clientId].bills[index].items.findIndex(item => item.id === id)
      if (itemIndex < 0) { return }
      state.clients[clientId].bills[index].items.splice(itemIndex, 1)
    },
    clearInfo(state) {
      state.clients = {}
      state.artchievedClients = {}
      state.clientsFetched = false
      state.artchievedClientsFetched = false
    }
  },
  actions: {
    async fetchUserClients({ commit }, { active }) {
      const uid = getAuth()?.currentUser?.uid

      try {
        const clientsRef = collection(getFirestore(), `apps/law-clients/clients`)
        const q = query(clientsRef, where('active', '==', active), where('userId', '==', uid))
        const clients = await getDocs(q)
        for (const clientDoc of clients.docs) {
          const data = clientDoc.data()
          data.id = clientDoc.id

          if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }

          await commit('setClientInfo', data)
        }

        if (active === true) {
          await commit('setClientsFetched')
        } else if (active === false) {
          await commit('setArchievedClientsFetched')
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async getClientInfo({ commit }, clientId) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }


      try {
        await getDoc(doc(getFirestore(), `apps/law-clients/clients/${clientId}`))
          .then(async info => {
            if (info.exists()) {
              let clientInfo = info.data()
              clientInfo.id = clientId
              if (clientInfo.timestamp && clientInfo.timestamp.toDate) { clientInfo.timestamp = clientInfo.timestamp.toDate() }
              await commit('setClientInfo', clientInfo)
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchClientBills({ commit }, { clientId, active }) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      const billsArray = []

      try {
        const billsRef = collection(getFirestore(), 'apps/law-clients/bills')
        const q = query(billsRef, where('active', '==', active), where('clientId', '==', clientId))
        const bills = await getDocs(q)
        for (const billDoc of bills.docs) {
          const data = billDoc.data()
          data.billId = billDoc.id
          if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }

          if (data.items) {
            for (const i in data.items) {
              if (data.items[i].timestamp && data.items[i].timestamp.toDate) { data.items[i].timestamp = data.items[i].timestamp.toDate() }
            }
          }

          billsArray.push(data)
        }

        for (const bill of billsArray) {
          await commit('addBillToStore', { clientId, bill })
        }

        if (!active) {
          await commit('setClientInfo', {
            id: clientId,
            archivedBillsLoaded: true
          })
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchClientComments({ commit }, clientId) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      const commentsArr = []

      try {
        const commentsRef = collection(getFirestore(), 'apps/law-clients/comments')
        const q = query(commentsRef, orderBy('timestamp', 'desc'), where('clientId', '==', clientId))
        const comments = await getDocs(q)
        for (const commentDoc of comments.docs) {
          const data = commentDoc.data()
          data.commentId = commentDoc.id
          if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }
          commentsArr.push(data)
        }

        await commit('setClientInfo', {
          id: clientId,
          comments: commentsArr
        })
      } catch (e) {
        commit('setError', e)
      }
    },
    async createClient({ commit }, { name }) {
      const { toastify } = useNotifications()
      let toastId

      try {
        if (!name || !name.length || !name.trim() || name.trim().length > 100) {
          toastify.error('Некорректное название клиента')
          return
        }

        toastId = toastify.warning('Сохраняю', { timeout: null })

        const batch = writeBatch(getFirestore())

        const clientId = doc(collection(getFirestore(), 'apps/law-clients/clients')).id

        const userId = getAuth()?.currentUser?.uid

        const clientInUserRef = doc(getFirestore(), `apps/law-clients/clients/${clientId}`)
        batch.set(clientInUserRef, {
          active: true,
          userId,
          timestamp: new Date(),
          name: name.trim()
        })

        await batch.commit()

        await commit('setClientInfo', {
          id: clientId,
          active: true,
          name: name.trim()
        })

        toastify.replace(toastId, 'Клиент создан', 'success')

        return clientId
      } catch (e) {
        toastify.remove(toastId)
        commit('setError', e)
        return
      }
    },
    async removeClient({ commit }, clientId) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      const projectIdsToDelete = []

      const batchArray = []
      batchArray.push(writeBatch(getFirestore()))
      let count = 0
      let batchIndex = 0
      const countLimit = 495

      try {
        const commentsRef = collection(getFirestore(), 'apps/law-clients/comments')
        const q2 = query(commentsRef, where('clientId', '==', clientId))
        await getDocs(q2)
          .then(comments => {
            comments.forEach(item => {
              const ref = doc(getFirestore(), item.ref.path)
              batchArray[batchIndex].delete(ref)
              count++
              if (count > countLimit) {
                batchArray.push(writeBatch(getFirestore()))
                batchIndex++
                count = 0
              }
            })
          })

        const projectsRef = collection(getFirestore(), 'apps/law-clients/projects')
        const q3 = query(projectsRef, where('clientId', '==', clientId))
        await getDocs(q3)
          .then(project => {
            project.forEach(async item => {
              projectIdsToDelete.push(item.id)

              const ref = doc(getFirestore(), item.ref.path)
              batchArray[batchIndex].delete(ref)
              count++
              if (count > countLimit) {
                batchArray.push(writeBatch(getFirestore()))
                batchIndex++
                count = 0
              }
            })
          })

        await batchArray.forEach(async batch => await batch.commit())

        await commit('setDeletedClient', clientId)

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async setArchieved({ commit }, { clientId, archieved }) {
      if (!clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const clientInUserRef = doc(getFirestore(), `apps/law-clients/clients/${clientId}`)
        batch.update(clientInUserRef, {
          active: !archieved
        })

        await batch.commit().then(async () => {
          await commit('setClientActive', {
            id: clientId,
            active: !archieved
          })

          return true
        })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async editClientName({ commit }, { clientId, name }) {
      if (!clientId || !name) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())
        const clientMainRef = doc(getFirestore(), `apps/law-clients/clients/${clientId}`)
        batch.update(clientMainRef, {
          name: name
        })

        await batch.commit().then(async () => {
          await commit('setClientName', {
            id: clientId,
            name: name
          })

          return true
        })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async editClientEntities({ commit }, { clientId, entities }) {
      if (!clientId || !entities) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())
        const clientDetailsRef = doc(getFirestore(), `apps/law-clients/clients/${clientId}`)
        batch.update(clientDetailsRef, {
          entities: entities
        })

        await batch.commit().then(async () => {
          await commit('setClientEntities', {
            id: clientId,
            entities: entities
          })

          return true
        })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async editClientLinks({ commit }, { clientId, links }) {
      if (!clientId || !links) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())
        const clientDetailsRef = doc(getFirestore(), `apps/law-clients/clients/${clientId}`)
        batch.update(clientDetailsRef, {
          links: links
        })

        await batch.commit().then(async () => {
          await commit('setClientLinks', {
            id: clientId,
            links: links
          })

          return true
        })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async createClientComment({ commit }, { clientId, comment }) {
      if (!clientId || !comment || !comment.length) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = getAuth()?.currentUser?.uid

      const commentData = {
        text: comment,
        userId: uid,
        timestamp: new Date(),
        clientId: clientId
      }

      try {
        const batch = writeBatch(getFirestore())

        const commentId = doc(collection(getFirestore(), 'apps/law-clients/comments')).id
        const clientDetailsRef = doc(getFirestore(), `apps/law-clients/comments/${commentId}`)
        batch.set(clientDetailsRef, commentData)

        await batch.commit()
          .then(async () => {
            commentData.commentId = commentId

            await commit('addClientComment', {
              id: clientId,
              comment: commentData
            })

            return true
          })
      } catch (e) {
        commit('setError', e)
        return false
      }

      return true
    },
    async addBill({ commit }, { billName, clientId }) {
      if (!clientId || !billName) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = getAuth()?.currentUser?.uid

      try {
        const batch = writeBatch(getFirestore())

        const billId = doc(collection(getFirestore(), 'apps/law-clients/bills')).id

        const data = {
          timestamp: new Date(),
          userId: uid,
          name: billName.trim(),
          active: true,
          items: [],
          clientId: clientId
        }

        const billRef = doc(getFirestore(), `apps/law-clients/bills/${billId}`)
        batch.set(billRef, data)

        await batch.commit()

        data.billId = billId

        await commit('addBillToStore', {
          clientId: clientId,
          bill: data
        })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteBill({ commit }, { billId, clientId }) {
      if (!billId || !clientId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const billRef = doc(getFirestore(), `apps/law-clients/bills/${billId}`)
        batch.delete(billRef)

        await batch.commit()

        await commit('deleteBillFromStore', { billId, clientId })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async archiveBill({ commit }, { billId, clientId }) {
      if (!clientId || !billId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const billRef = doc(getFirestore(), `apps/law-clients/bills/${billId}`)
        batch.update(billRef, { active: false })

        await batch.commit()

        await commit('updateBillInStore', {
          clientId,
          billId,
          data: { active: false }
        })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async addBillItem({ commit }, { billId, clientId, data }) {
      if (!clientId || !billId || !data || !data.name || !data.sum === null || !data.date) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = getAuth()?.currentUser?.uid

      try {
        const batch = writeBatch(getFirestore())

        data.timestamp = new Date()
        data.userId = uid
        data.id = +(Date.now() + Math.random())

        const billRef = doc(getFirestore(), `apps/law-clients/bills/${billId}`)
        batch.update(billRef, {
          items: arrayUnion(data)
        })

        await batch.commit()

        await commit('addBillItemToStore', {
          clientId: clientId,
          billId: billId,
          data: data
        })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteBillItem({ commit, getters }, { billId, clientId, id }) {
      if (!clientId || !billId || !id) {
        commit('setError', 'Ошибка')
        return false
      }

      try {

        const index = getters.clients[clientId].bills.findIndex(bill => bill.billId === billId)
        let items = getters.clients[clientId].bills[index].items || []

        const batch = writeBatch(getFirestore())

        const billRef = doc(getFirestore(), `apps/law-clients/bills/${billId}`)
        batch.update(billRef, {
          items: items.filter(item => item.id !== id)
        })

        await batch.commit()

        await commit('removeBillItemToStore', {
          clientId: clientId,
          billId: billId,
          id: id
        })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    clients: s => s.clients,
    clientsFetched: s => s.clientsFetched,
    artchievedClientsFetched: s => s.artchievedClientsFetched
  }
}