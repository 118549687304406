<template>
  <nav class="bg-gray-500 shadow-sm fixed top-0 left-0 right-0 z-10 overflow-x-scroll">
    <div class="mx-2 px-2 py-2">
      <div class="relative flex items-center justify-between h-10">
        <div class="flex items-center justify-start">
          <div class="block">
            <div class="flex space-x-4">
              <router-link
                v-if="$route.name !== 'Clients'"
                to="/"
                class="bg-gray-800 hover:bg-gray-700 hover:text-white text-white px-3 py-2 rounded-md text-sm font-medium w-max"
              >Все клиенты</router-link>

              <router-link
                v-if="$route.name !== 'Check'"
                to="/check"
                class="bg-gray-800 hover:bg-gray-700 hover:text-white text-white px-3 py-2 rounded-md text-sm font-medium w-max"
              >Проверки</router-link>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end pr-2">
          <div v-if="email" class="w-max ml-3 text-gray-900">{{ email }}</div>

          <div class="ml-3">
            <div
              class="cursor-pointer"
              title="Выйти"
              @click.prevent.stop="logout()"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { getLogoutURL } from '../../../../../settings/auth';
import { APP_ID } from '../../../services/settings';

export default {
  name: 'NavbarNav',
  props: {
    email: {
      type: String,
      default: null
    }
  },
  methods: {
    logout() {
      window.location.href = getLogoutURL(APP_ID) + '&authMessage=' + encodeURI('Успешно')
    }
  }
}
</script>