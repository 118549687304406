import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import { setPageTitle } from '../../../settings/page-meta'
import { APP_NAME } from '../services/settings'

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    meta: { title: 'Авторизация', hideNavbar: true },
    component: () => import('./../views/Auth.vue')
  },
  {
    path: '/',
    name: 'Clients',
    meta: { auth: true, title: 'Клиенты' },
    component: () => import('../views/Clients.vue')
  },
  {
    path: '/client/:id/view',
    name: 'ViewClient',
    meta: { auth: true, title: 'Клиент' },
    component: () => import('../views/ViewClient.vue')
  },
  {
    path: '/check',
    name: 'Check',
    meta: { auth: true, title: 'Проверки' },
    component: () => import('../views/Check.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title, `[${APP_NAME}]`)

  const currentUser = getAuth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    if (to.name !== 'Auth') {
      next({ name: 'Auth' })
      return
    }
  }

  next()
})

export default router
