import { getAuth, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth'
import router from '../router'
import { firebaseErrorCodes } from '../../../settings/firebase-errorCodes'
import { getAppStartPageFromCookie } from '../../../settings/cookies'
import { APP_ID } from '../services/settings'

export default {
  actions: {
    async loginWithEmailAndPassword({ commit }, { email, password }) {
      if (process.env.NODE_ENV === 'production') { return }

      if (!email) {
        commit('setError', 'Не указан email')
        return
      }
      if (!password) {
        commit('setError', 'Не указан пароль')
        return
      }

      try {
        const auth = getAuth()
        await signInWithEmailAndPassword(auth, email, password)
        router.replace({ path: '/' })
      } catch (err) {
        commit('setError', err)
        return false
      }
    },
    async login({ commit }, { customToken, authErrorNavigateStr }) {
      try {
        if (!customToken) {
          commit('setError', 'Ошибка')
          return
        }

        const auth = getAuth()
        const userCredential = await signInWithCustomToken(auth, customToken)
        if (!userCredential || !userCredential.user || !userCredential.user.uid) {
          window.location.replace(authErrorNavigateStr)
          return
        }

        let redirectPath = '/'
        let redicectQuery = {}

        const startPage = getAppStartPageFromCookie(APP_ID)
        if (startPage && startPage[0] !== router.currentRoute.value.path) {
          redirectPath = startPage[0]
          redicectQuery = startPage[1]
        }

        router.replace({ path: redirectPath, query: { ...redicectQuery } })
      } catch (e) {
        if (e.code && firebaseErrorCodes[e.code]) {
          commit('setError', firebaseErrorCodes[e.code])
        } else {
          commit('setError', e)
        }
        throw e
      }
    }
  }
}